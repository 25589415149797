import React                   from 'react';
import styled                  from '@emotion/styled';
import { Container, Row, Col } from 'react-grid-system';

import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import { Button, Link }  from '@interness/web-core/src/components/elements'
import Separator         from '@interness/web-core/src/components/structure/Separator/Separator';
import RandomBrandHeaderImages
                         from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import MoreExpander      from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';

const dispalyItems = [
  {
    title: 'Schmuck',
    descr: 'Schmuck ist Inspiration und Leidenschaft. Aber auch die Natürlichkeit und Authentizität unserer Schmuckkollektionen sind uns besonders wichtig.',
    image: 'display/jewelry.jpg',
    path: '/jewelry'
  },
  {
    title: 'Trauringe',
    descr: 'Ein Trauring ist Symbol für die Liebe und soll auch im Alltag die Erinnerung an den schönsten Moment im Leben jeden Tag wieder neu erstrahlen lassen.',
    image: 'display/wedding-rings.jpg',
    link: 'https://trauringstudio-solingen.de/'
  },
  {
    title: 'Verlobungsringe',
    descr: 'Der Verlobung gehen die Fragen aller Fragen voraus. Will ich sie? Will sie mich? Passen wir wirklich bis an unser Lebensende zusammen?',
    image: 'display/engagement_rings.jpg',
    link: 'https://trauringstudio-solingen.de/verlobungsringe'
  }
];

const Display = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const DisplayItem = styled.div`
  width: 22%;
  margin-bottom: 50px;
  position: relative;
  @media (max-width: 960px) {
    width: 48%;
  }
  @media (max-width: 640px) {
    width: 100%;
  }

  > div {
    padding: 10px 0 0 0;
    margin-bottom: 20px;
  }

  img {
    margin-bottom: 0;
  }

  h4 {
    margin: 10px 0 5px 0;
    text-transform: uppercase;
    font-weight: bold;
    text-align: left;
  }

  h5 {
    font-size: 1.1rem;
    font-weight: 300;
    text-align: left;
  }

  p {
    text-align: justify;
    font-size: 0.9rem;
  }

  a {
    position: absolute;
    bottom: -42px;
  }
`;

const Vouchers = styled.div`
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    width: 48%;
    margin-bottom: 50px;
    @media (max-width: 640px) {
      width: 100%;
    }
  }

  h3 {
    font-weight: bold;
  }
`;

const List = styled.ul`
  margin-left: 0;

  li {
    margin: 0;
  }
`;


function IndexPage(props) {
  return (
    <>
      <RandomBrandHeaderImages count={3}/>
      <Wrapper>
        <Spacer/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'}>Juwelier Leiber</Heading>
          <span style={{ textAlign: 'justify' }}>
            <p>Seien Sie herzlich willkommen bei Juwelier Leiber, im Herzen der Klingenstadt Solingen. Juwelier Leiber ist seit 1843 ein Begriff für Schmuck, Trauringe und Eheringe im Bergischen Land.</p>
            <p>Bei Juwelier Leiber sind Sie und Ihre Wünsche in den besten Händen. Stöbern Sie ein wenig auf unserer Internet-Seite und informieren Sie sich über unser Angebot. Ganz gleich was Sie suchen oder welche Frage Sie haben, wir sind sicher, Ihnen helfen zu können.</p>
            <p>In unseren Geschäftsräumen möchten wir Ihnen ein noch schöneres Einkaufserlebnis ermöglichen und legen größten Wert auf persönliche Beratung und individuellen Service. Bei uns finden Sie auch Accessoires, die Sie von uns vielleicht nicht erwartet hätten.</p>
            <p>In unserem über dem eigentlichen Juweliergeschäft gelegenen Trauringstudio präsentieren wir in ruhiger und entspannter Atmosphäre abseits vom normalen Geschäftsbetrieb unsere große Auswahl an Trauringen, Eheringen und vielfältige Möglichkeiten, die Trau(m)ringe fürs Leben zu finden.</p>
            <p>Wir bieten Ihnen einen ausgezeichneten Service mit Pflege und Reparaturen von Ihren Lieblingstücken.<b/>
            Wir kaufen Ihr Altgold zu fairen und tagesaktuellen Preisen an.</p>
            <p>Bis bald in Solingen.<b/>Ihr Team von Juwelier Leiber.</p>
          </span>
          <div style={{ textAlign: 'center' }}>
            <h3>Besuchen Sie uns auch bei Facebook oder Instagram!</h3>
            <Button style={{ marginRight: '10px' }} as={Link} external
                    to={'https://www.instagram.com/juwelier_leiber_solingen/'}>Instagram</Button>
            <Button as={Link} external to={'https://www.facebook.com/juwelier.leiber/'}>Facebook</Button>
          </div>
        </section>
        <Spacer/>
        <LiveAnnouncements/>
        <Separator/>
        <section>
          <Heading tag={'h2'}>Ein besonderes Erlebnis</Heading>
          <Display>
            {dispalyItems.map(item => (
              <DisplayItem key={item.title}>
                <div>
                  <img src={item.image} alt={item.title}/>
                  <h4>{item.title}</h4>
                  <p dangerouslySetInnerHTML={{ __html: item.descr }}/>
                </div>
                {item.path && <Button as={Link} to={item.path}>Mehr erfahren...</Button>}
                {item.link &&
                <Button as={'a'} href={item.link} title={item.title} target="_blank" rel="noopener noreferrer">Mehr
                  erfahren...</Button>}
              </DisplayItem>
            ))}
          </Display>
          <Spacer/>
        </section>
        <Separator/>
        <section>
          <Heading tag={'h2'}>Unser Service für Sie</Heading>
          <p>Kompletter Service ist für unser Team selbstverständlich. Darum werden Schmuck und Uhren von uns repariert,
            umgearbeitet oder angefertigt. So erfüllen wir Ihre Wünsche schnell, sicher und mit
            persönlichem Ansprechpartner.</p>
          <MoreExpander>
            <Container fluid>
              <Row>
                <Col md={6}>
                  <h3>Armbanduhren</h3>
                  <List>
                    <li>mechanische Uhren / Handaufzug</li>
                    <li>Quarzarmbanduhren</li>
                    <li>Automatikuhren</li>
                    <li>Batteriewechsel</li>
                    <li>Komplette Revisionen</li>
                    <li>Taschenuhren</li>
                    <li>Glasersatz</li>
                    <li>Leder-, Metall-, Kunststoffuhrarmbänder</li>
                    <li>Bandanpassung</li>
                  </List>
                </Col>
                <Col md={6}>
                  <h3>Goldschmiedereparaturen</h3>
                  <List>
                    <li>Goldschmiedereparaturen aller Art</li>
                    <li>Kette und Ösen zulöten, Verschlüsse ersetzen</li>
                    <li>Ringgrößen ändern</li>
                    <li>Perlenketten neu fassen oder knoten</li>
                    <li>Trauringumarbeitung</li>
                    <li>Neuanfertigungen</li>
                    <li>Schmuck reinigen und aufarbeiten</li>
                  </List>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <h3>Altgoldankauf</h3>
                  <List>
                    <li>Wir prüfen fachkundig und unkompliziert und vergüten nach aktuellen Edelmetallkursen.</li>
                    <li>Altgoldankauf ist Vertrauenssache</li>
                  </List>
                </Col>
              </Row>
            </Container>
          </MoreExpander>
        </section>
        <Separator/>
        <section>
          <Heading tag={'h2'}>Unsere Gutscheine und Gutsteine</Heading>
          <p>Wenn Sie sich bei der Auswahl eines Geschenkes nicht ganz sicher sind, so lassen Sie doch einfach den
            Beschenkten sein Lieblingsstück selbst auswählen. Sie können bei uns die Gutscheine oder Gutsteine online
            bestellen und wir schicken Ihnen diese per Post zu.</p>
          <Spacer height={20}/>
          <Vouchers>
            <div>
              <h3>Gutschein</h3>
              <img src="/voucher.jpg" alt="Gutschein"/>
              <Button as={Link} to={'/gutschein'}>Hier geht es zu den Gutschein</Button>
            </div>
            <div>
              <h3>Gutstein</h3>
              <img src="/stone-voucher.jpg" alt="Gutstein"/>
              <Button as={Link} to={'/gutstein'}>Hier geht es zu den Gutsteinen</Button>
            </div>
          </Vouchers>
        </section>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;